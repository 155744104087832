import { Button } from "@mui/material";
import React from "react";

/**
 * Custom SignIn component meant to be used in the main navigation bar.
 * @param {function} logInCallBack  logIn callback function reference.
 * @returns
 */
const SignInButton = ({ logInCallBack }) => {
  return (
    <Button
        variant="contained"
        onClick={logInCallBack}
      >
        Log In
    </Button>
  );
};

export default SignInButton;
