import React, { useRef } from 'react';
import { Button, Grid, IconButton, Stack, TextField } from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const authKeyStyles = {
  width: '100%',
};

const mainGridStyles = {
  margin: '1em',
};

export default function AuthKeyGenerator({
  usernameLabel = 'Client ID',
  passwordLabel = 'Secret Client Key',
  encryptedLabel = 'Authorization Key',
}) {
  const [authKey, setAuthKey] = React.useState('');
  const clientIdRef = useRef('');
  const secretClientIdRef = useRef('');

  const encodeValues = () => {
    const clientId = clientIdRef.current.value;
    const secretClientId = secretClientIdRef.current.value;
    if (clientId && clientId.length > 0 && secretClientId && secretClientId.length > 0) {
      setAuthKey(btoa(`${clientId}:${secretClientId}`));
    }
  };

  const copyAuthorizationKeyToClipboard = () => {
    navigator.clipboard.writeText(authKey);
  };

  return (
    <>
      <Grid container spacing={2} sx={mainGridStyles}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <TextField
              id="client-id"
              label={usernameLabel}
              variant="outlined"
              inputRef={clientIdRef}
            />
            <TextField
              id="secret-client-id"
              label={passwordLabel}
              variant="outlined"
              inputRef={secretClientIdRef}
            />
            <Button variant="contained" onClick={encodeValues}>
              Encode
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={'end'}>
            <Grid item xs={8} md={5}>
              <TextField
                id="auth-key"
                label={encryptedLabel}
                variant="standard"
                aria-readonly={true}
                sx={authKeyStyles}
                value={authKey}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={copyAuthorizationKeyToClipboard}>
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
