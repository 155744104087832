import { DOMAIN } from '../../utils/auth-constants';

let baseUrl = '';
if (
  process.env.TARGET_ENV === 'local' &&
  (!process.env.DOMAIN || process.env.DOMAIN === 'localhost')
) {
  baseUrl = 'http://console.dev.infinant.com';
} else {
  baseUrl = `https://console${DOMAIN}`;
}
const consoleUrl = baseUrl;

export default consoleUrl;
