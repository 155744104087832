import React from "react";
import './signoutbutton.css';

/**
 * Custom SigOut component meant to be used in the main navigation bar.
 * @param {string} className custom logout className.
 * @param {function} logOutCallBack  logout callback function reference.
 * @returns
 */
const SignOutButton = ({ logOutCallBack, className }) => {
  return (
    <button onClick={logOutCallBack} aria-label="signOut" title="Sign Out" className={className}>
        <img
          src="/assets/infinant/dev-portal/images/logout-icon.svg"
          height= "24px"
          width= "24px"
          className="logout-icon-color"
        />
    </button>
  );
};

export default SignOutButton;
