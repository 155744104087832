import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

export function Hero({ title, paragraphsDescription, imageUrl, owner }) {
  if (owner === 'fis') {
    return (
      <Grid
        item
        container
        minHeight={400}
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url(${imageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Grid
          item
          container
          maxWidth="1920px"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: { xs: '40px', md: '40px 96px 20px 96px', lg: '40px 120px 0 120px' } }}
        >
          <Typography
            textAlign={'center'}
            variant={'h2'}
            paddingY="30px"
            fontWeight="bold"
            color="#FFF"
          >
            {title}
          </Typography>

          {paragraphsDescription.map((p) => (
            <Typography textAlign={'center'} key={p} variant="body1" color="#FFF">
              {p}
              <br></br>
              <br></br>
            </Typography>
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      item
      backgroundColor="#FBFBFB"
      maxWidth="1920px"
      sx={{ padding: { xs: '40px 40px 0 40px', md: '40px 96px 0 96px' } }}
      marginBottom={'40px'}
    >
      <Grid item xs={12} md={7}>
        <Typography variant="h3" paddingY="40px" fontWeight="bold" color="#1A1A1A">
          {title}
        </Typography>

        {paragraphsDescription.map((p) => (
          <Typography key={p} variant="body1">
            {p}
            <br></br>
            <br></br>
          </Typography>
        ))}
      </Grid>
      <Grid item margin="auto">
        <Box marginX="auto" component="img" src={imageUrl} xs={12} md={5} />
      </Grid>
    </Grid>
  );
}
