import { createTheme, ThemeOptions } from "@mui/material/styles";
import { Theme } from "@mui/material";
import customThemeStyles from "../../../styles/custom-styles";

const styles: ThemeOptions = {
  palette: {
    primary: {
      main: "#4BCD3E",
    },
    text: {
      primary: "#1A1A1A",
      secondary: "#626262",
    },
  },
  typography: {
    fontFamily: customThemeStyles?.MUIfonts?.fontFamily,
  },
};

const buttonStyle = {
  lineHeight: 1,
  fontWeight: 700,
  textTransform: "none",
  padding: "14px 40px 14px 40px",
  borderRadius: "30px",
  fontSize: "14px",
};

const outlinedButtonBorderWith = "2px";
// @ts-ignored
const primaryMain = styles.palette.primary.main;

// @ts-ignore
const customComponentOverwrite = {
  // Any component specifics of extras can be added here
  components: {
    MuiButton: {
      styleOverrides: {
        contained: buttonStyle,
        outlined: buttonStyle,
        outlinedPrimary: {
          color: "black",
          // @ts-ignored
          borderColor: primaryMain,
          borderWidth: outlinedButtonBorderWith,
          "&:hover": {
            // @ts-ignored
            border: outlinedButtonBorderWith + " solid " + primaryMain,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        },
        input: {
          paddingTop: "12.5px",
          paddingBottom: "12.5px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: "translate(14px, 12.5px) scale(1)",
        },
        shrink: {
          transform: "translate(14px, -9px) scale(0.75)",
        },
      },
    },
  },
};

export const getFisMuiTheme = (): Theme => {
  const baseTheme = createTheme(styles);
  return createTheme(baseTheme, customComponentOverwrite);
};
