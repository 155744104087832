import React from 'react';
import {Button} from "@mui/material";

const isBrowser = () => typeof window !== "undefined"


export default function ErrorPage() {

  let isAuth0Error = false;
  let errorMessage = "";

  if (isBrowser()) {
    const params= new URLSearchParams(window.location.search);
    isAuth0Error = params.get("__api-deny-auth0")?.toUpperCase() === "TRUE";
    errorMessage = params.get("__api-deny-auth0-message") || "Unexpected Error";

    if (!isAuth0Error) {
      window.location.replace(window.location.origin);
    }
  }

  return (
    isAuth0Error? <>
      <div className="error-container">
        <div className="error-panel">
          <div style={{ margin: "auto", textAlign: "center" }}>
            <div style={{ marginBottom: "10px", marginTop: "30px" }}>
              <h3 className="left-login-header">We are unable to log you in.</h3>
              <p style={{ marginBottom: "0px", marginTop: "20px" }}>Reason:</p>
              <p style={{ marginBottom: "10px", marginTop: "5px" }}>{errorMessage}</p>
            </div>
            <div style={{ marginTop: "30px" }}>
              <Button
                variant="contained"
                onClick={() => {
                  window.location.replace(window.location.origin);
                }}
              >
                Click here to return to home page
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>: <></>
  );
};
