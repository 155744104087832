import React, { useEffect } from 'react';
import { API } from '@stoplight/elements';
import { StoplightProject } from '@stoplight/elements-dev-portal';
import styled from '@emotion/styled';
import './stoplight.css';

export default function ApiDocumentation({ apiDescriptionUrl }) {
  const [layoutType, setLayoutType] = React.useState('sidebar');
  const customBasePath = process.env.CUSTOM_API_YAML_DEFINITION_BASE_URL;
  const theme = process.env.THEME?.toLowerCase() || 'infinant';
  const customThemeiD = `${theme}theme`;

  const PROJECT_ID = process.env.STOPLIGHT_ID || 'cHJqOjE2NjUwMA';
  const HIDE_MOCKING = process.env.STOPLIGHT_HIDE_MOCKING?.toUpperCase() === 'TRUE';
  const isStoplightProject = !!PROJECT_ID;

  const url =
    (customBasePath && customBasePath.length > 0 ? customBasePath : '') + apiDescriptionUrl;

  const ApiContainer = styled.div`
    @media (min-width: 1330px) {
      width: 80vw;
    }
  `;

  useEffect(() => {
    const handler = (e) => {
      setLayoutType(e.matches ? 'sidebar' : 'stacked');
    };
    window.matchMedia('(min-width: 1330px)').addEventListener('change', handler);
  }, []);

  return (
    <div id={customThemeiD}>
      {isStoplightProject ? (
        <div id="sl-wrapper" style={{ height: '90vh' }}>
          <StoplightProject
            projectId={PROJECT_ID}
            router={'memory'}
            layout={layoutType}
            hideMocking={HIDE_MOCKING}
          />
        </div>
      ) : (
        <ApiContainer>
          <API apiDescriptionUrl={url} router={'memory'} layout={layoutType} />
        </ApiContainer>
      )}
    </div>
  );
}
