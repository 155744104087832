import React, {useEffect} from 'react';
import { navigate } from 'gatsby';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
import {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_CACHE_TYPE,
  AUTHENTICATED_RESOURCES,
} from '../../../../utils/auth-constants';
import { getAudienceId } from '../../../../utils/environment';

const AUTH0_CONFIG = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  cacheLocation: AUTH0_CACHE_TYPE,
  useRefreshTokens: true,
};

const AuthenticatedPage = ({ children }) => {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();

  if (isLoading) {
    return <div></div>;
  } else {
    if (isAuthenticated) {
      if (user.tenant_approved) return <>{children}</>;
      else logout({ returnTo: window.location.origin + '?unauthorized' });
    } else {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }
};

const GatsbyPage = ({ children, ...props }) => {
  let authenticatedPaths = AUTHENTICATED_RESOURCES ?? '';
  authenticatedPaths = authenticatedPaths.split(',');
  const matchedAuthPath = authenticatedPaths.find(function (authPath) {
    return props.path.includes(authPath);
  });

  if (matchedAuthPath) {
    return <AuthenticatedPage>{children}</AuthenticatedPage>;
  } else {
    return <>{children}</>;
  }
};

const getCurrentBaseUrl = () => {
  return window.location.origin;
};


const RedirectAfterLogin = (props) => {
  const { isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    if (!(isAuthenticated && props.auth0Code && props.auth0State)) {
      if ("access_denied" === props.auth0Error) {
        const message = encodeURIComponent(props.auth0ErrorDescription);
        const url =
          getCurrentBaseUrl() + "/redirect/message?__api-deny-auth0=TRUE&__api-deny-auth0-message=" + message;
        logout({
          returnTo: url,
        });
      }
    }
  }, []);

  return <GatsbyPage {...props.parentProps}>{props.parentChildren}</GatsbyPage>;
};

const AuthenticatedAuth0GatsbyPage = ({ children, ...props }) => {
  const params= new URLSearchParams(window.location.search);
  //Auth0 params:
  const auth0Code = params.get("code");
  const auth0State = params.get("state");
  const auth0Error = params.get("error");
  const auth0ErrorDescription = params.get("error_description");

  const onRedirectCallback = (appState) => {
    // Use Gatsby's navigate method to replace the url
    navigate(appState?.returnTo || '/', { replace: true });
  };

  return (
    <Auth0Provider
      {...AUTH0_CONFIG}
      audience={getAudienceId()}
      redirectUri={window.location.href}
      onRedirectCallback={onRedirectCallback}
    >
      <RedirectAfterLogin
        auth0Code={auth0Code}
        auth0State={auth0State}
        auth0Error={auth0Error}
        auth0ErrorDescription={auth0ErrorDescription}
        parentProps={props}
        parentChildren={children}
      />
    </Auth0Provider>
  );
};

export default AuthenticatedAuth0GatsbyPage;
