import React from 'react';
import AuthenticatedAuth0GatsbyPage from './src/components/Authentication/Auth0/Gatsby/AuthenticatedAuth0GatsbyPage';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This tutorial has been updated. ` + `Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};

export const wrapPageElement = ({ element, props }) => {
  return <AuthenticatedAuth0GatsbyPage {...props}>{element}</AuthenticatedAuth0GatsbyPage>;
};
