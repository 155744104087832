import { createTheme, ThemeOptions } from "@mui/material/styles";
import { Theme } from "@mui/material";
import customThemeStyles from "../../../styles/custom-styles";

const fontNeuzeitBoldFamilyValues = ["Neuzeit Grotesk Bold", "Roboto"].join(",");

const styles: ThemeOptions = {
  palette: {
    primary: {
      main: "#1170CF",
    },
    text: {
      primary: "#1A1A1A",
      secondary: "#8F8F8F",
    },
  },
  typography: {
    fontFamily: customThemeStyles?.MUIfonts?.fontFamily,
    button: {
      fontFamily: fontNeuzeitBoldFamilyValues,
      lineHeight: 1,
      fontSize: "1rem",
      fontWeight: 700,
      textTransform: "none",
    },
  },
};

const customComponentOverwrite = {
  // Any component specifics of extras can be added here
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "16px",
          paddingBottom: "16px",
          borderRadius: "10px",
          boxShadow: "0px 3px 3px #C2C2C2",
        },
        outlined: {
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "15px",
          paddingBottom: "15px",
          borderRadius: "10px",
          boxShadow: "0px 3px 3px #C2C2C2",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
        input: {
          paddingTop: "12.5px",
          paddingBottom: "12.5px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: "translate(14px, 12.5px) scale(1)",
        },
        shrink: {
          transform: "translate(14px, -9px) scale(0.75)",
        },
      },
    },
  },
};

export const getInfinantMuiTheme = (): Theme => {
  const baseTheme = createTheme(styles);
  return createTheme(baseTheme, customComponentOverwrite);
};
