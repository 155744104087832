import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

export interface ConfirmationDialogProp {
  open: boolean;
  title: string;
  body: string;
  confirmText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  remainingSeconds?: number;
  hideConfirm?: boolean;
}

export function ConfirmationDialog({
  remainingSeconds,
  open,
  title,
  body,
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
  hideConfirm = false,
}: ConfirmationDialogProp) {
  const handleYes = () => {
    if (onConfirm) onConfirm();
  };
  const handleNo = () => {
    if (onCancel) onCancel();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: "723px", borderRadius: "10px" } }}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ paddingLeft: "48px", paddingTop: "35px", paddingBottom: "0px" }}
      >
        {title}
      </DialogTitle>
      <DialogContent style={{ paddingLeft: "48px", paddingTop: "32px" }}>
        <DialogContentText id="alert-dialog-description">
          <Typography component={"span"} variant="body2">
            {body}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ paddingRight: "46px", paddingBottom: "36px", paddingTop: "19px" }}>
        <Button variant="outlined" onClick={handleNo}>
          {cancelText ?? "No"} {hideConfirm && remainingSeconds ? ` (${remainingSeconds})` : ""}
        </Button>
        {!hideConfirm && (
          <Button variant="contained" onClick={handleYes} style={{ marginLeft: "24px" }}>
            {confirmText ?? "Yes"} {remainingSeconds ? ` (${remainingSeconds})` : ""}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
