import {UserServiceType} from "../user.service";
import {Client} from "./auth0.client";
import {AuthenticatedUserData} from "../../../model";
import {getAudienceId} from "../../../utils/environment";

const getUserData = async (): Promise<AuthenticatedUserData> => {
  const userData: AuthenticatedUserData = { isLogged: false };
  try {
    const user = await Client.getUser();
    if (user !== null) {
      const { email } = user;
      userData.userEmail = email;
      userData.firstName = user.given_name ?? "User";
      userData.lastName = user.family_name ?? "";
      userData.userName = `${userData.firstName || ""}${userData.lastName ? " " + userData.lastName : ""}`;
      userData.isLogged = true;
    }
  } catch (err) {}
  return Promise.resolve(userData);
};

const isUserLogged = async (): Promise<boolean> => {
  let isLogged: boolean = false;
  try {
    const user = await Client.getUser();
    isLogged = user !== null;
  } catch (err) {}
  return Promise.resolve(isLogged);
};

const signOut = async (): Promise<boolean> => {
  let successSignOut = false;
  try {
    await Client.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  } catch (err) {
    successSignOut = false;
  }
  return Promise.resolve(successSignOut);
};


const isAccessTokenValid = async (): Promise<boolean> => {
  try {
    const token = await Client.getTokenSilently({
      authorizationParams: {
        audience: getAudienceId(),
      },
    });
    if (token) return true;
  } catch (e) {
    console.error(e);
  }
  return false;
};

const refreshToken = async (): Promise<boolean> => {
  try {
    await Client.checkSession();
  } catch (e) {}
  return false;
};

export const UserServiceAuth0Impl: UserServiceType = {
  getUserData: getUserData,
  isUserLogged: isUserLogged,
  signOut: signOut,
  isAccessTokenValid: isAccessTokenValid,
  refreshSession: refreshToken,
};
