import axios, { AxiosRequestConfig } from "axios";
import UUIDUtil from "../../utils/uuid-util";

const API_GATEWAY_CONTACT_SUPPORT_URL = `${process.env.API_GATEWAY_CONTACT_SUPPORT_URL}`;
const BASE_VERSION_URL = "/interlace/v1/";
const BASE_SUPPORT = "support";

const SupportService = {
  sendMessage: async (body: any): Promise<any> => {
    const axiosClient = axios.create({
      baseURL: API_GATEWAY_CONTACT_SUPPORT_URL,
    });
    const config: AxiosRequestConfig = {
      headers: { 
        "x-rquid": UUIDUtil.generateUUID(),
        "x-idempotency-key": UUIDUtil.generateUUID(),
      },
    };
    const response = await axiosClient.post(
      `${BASE_VERSION_URL}${BASE_SUPPORT}`,
      body,
      config
    );
    return response?.data;
  },
};

export default SupportService;
