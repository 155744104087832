import { Auth0Client } from "@auth0/auth0-spa-js";
import {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_CACHE_TYPE,
} from '../../../utils/auth-constants';

const auth0: Auth0Client = new Auth0Client({
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  cacheLocation: AUTH0_CACHE_TYPE,
  useRefreshTokens: true,
});

export const Client = auth0;
