import { useIdleTimer } from "react-idle-timer";
import { useEffect, useState } from "react";
import { PROMPT_MILLIS_BEFORE_IDLE } from "./constants";
import { getIdleTimeout } from "../../utils/environment";

const IDLE_TIMEOUT_MILLIS = getIdleTimeout();

interface Props {
  executeOnIdle?: () => void;
}

export function usePromptTime({ executeOnIdle }: Props) {
  const [promptEnabled, setPromptEnabled] = useState(false);
  const [remainingIdle, setRemainingIdle] = useState<number>(IDLE_TIMEOUT_MILLIS);

  const onIdle = () => {
    setPromptEnabled(false);
    if (executeOnIdle) executeOnIdle();
  };

  const onActive = () => {
    setPromptEnabled(false);
  };

  const onPrompt = () => {
    setPromptEnabled(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: IDLE_TIMEOUT_MILLIS,
    promptBeforeIdle: PROMPT_MILLIS_BEFORE_IDLE,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingIdle(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return [promptEnabled, remainingIdle, activate] as const;
}
