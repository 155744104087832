import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import config from 'config';

const Seo = ({ frontmatter, title, url }) => {
  const description = frontmatter.description
    ? frontmatter.description
    : config.metadata.description;
  const image = frontmatter.cover ? frontmatter.cover : config.metadata.siteImage;

  return (
    <Helmet
      htmlAttributes={{
        lang: config.metadata.language,
        prefix: 'og: http://ogp.me/ns#',
      }}
    >
      {/* General tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      {process.env.TARGET_ENV !== 'local' && (
        <meta
          httpEquiv="Content-Security-Policy"
          content="default-src 'self' data:; script-src 'self' 'unsafe-inline' 'unsafe-eval'; img-src * 'self' https: blob: data:; child-src 'self'; style-src 'self' 'unsafe-inline'; connect-src 'self' *.algolianet.com *.algolia.net *.algolia.com stoplight.io https://*.us.auth0.com https://*.infinant.com https://*.fisglobal.com; object-src 'none'; script-src-elem 'self' 'unsafe-eval' 'unsafe-inline';"
        />
      )}
      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* <meta
        name="twitter:creator"
        content={config.authorTwitterAccount ? config.authorTwitterAccount : ''}
      /> */}
    </Helmet>
  );
};

Seo.propTypes = {
  frontmatter: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Seo;
