import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';
import {
  Header,
  MdxComponents,
  SearchSidebar,
  ScrollTop,
  Sidebar,
  TableOfContents,
  ThemeProvider,
  Footer,
} from '../';

import config from 'config';
import React, { useRef, useEffect, useState } from 'react';
import { Slide } from 'react-reveal';
import { hiddenMobile, hiddenTablet } from '../../styles';
import { onMobile, onTablet } from '../../styles/responsive';
import 'css';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { mainMuiTheme } from '../../theme/mui-theme';
import { SessionExpiredDialogWrapper } from '../SessionExpiredDialog/wrapper';
import { isFisTheme } from '../../utils/environment';

const Wrapper = styled.div`
  display: flex;
  overflow-wrap: anywhere;
  justify-content: space-between;
  position: relative;

  ${onMobile} {
    min-height: 95vh;
  }
`;

const Content = styled('main')`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 50px 70px;
  background-color: ${(props) => props.theme.content.background};

  ${onTablet} {
    padding: 30px;
  }
  ${onMobile} {
    padding: 15px;
  }
`;

const ContentNoPadding = styled('main')`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0px;
  background-color: ${(props) => props.theme.content.background};
`;

/**
 * Hook that alerts clicks outside of the passed ref
 */
function actOnClose(ref, onClose) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose(ref.current);
      }
    }

    function handleEscape(event) {
      if (event.key === 'Escape') {
        onClose(ref.current);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('keydown', handleEscape);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [ref]);
}

const Layout = ({ children, location, data }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [fullscreenMode, setFullScreenMode] = useState(false);
  const themeProviderRef = React.createRef();
  const searchSidebarRef = useRef(null);
  const closeSearch = () => setShowSearch(false);
  actOnClose(searchSidebarRef, closeSearch);

  const currentPathLocation = location
    ? location.pathname.endsWith('/') && location.pathname.length > 1
      ? location.pathname.slice(0, -1)
      : location.pathname
    : '';

  const excludeSidebar = config.sidebar.excludePaths
    ? config.sidebar.excludePaths.includes(currentPathLocation)
    : false;

  const includeFooter = () => {
    if (!config.footer.enabled) return false;
    if (config.footer.includeAllPaths) return true;
    return config.footer.includePaths.includes(currentPathLocation);
  };

  const [apiViewMode, setApiViewMode] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isApiViewEnabled = isFisTheme() && location.pathname === '/' && params.has('apispecs');
    setApiViewMode(isApiViewEnabled);
  }, []);

  return (
    <MuiThemeProvider theme={mainMuiTheme}>
      <ThemeProvider ref={themeProviderRef} darkModeConfig={config.features.darkMode}>
        {config.header.enabled === true ? (
          <>
            <div
              css={{
                zIndex: 20,
                position: 'relative',
                display: showSearch || searchVisible ? 'block' : 'none',
              }}
            >
              <Slide right delay={0} duration={400} when={showSearch}>
                <SearchSidebar
                  ref={searchSidebarRef}
                  onVisibleChange={setSearchVisible}
                  closeSelf={closeSearch}
                />
              </Slide>
            </div>
            <Header
              show={!(config.features.fullScreenMode.hideHeader && fullscreenMode)}
              location={location}
              setShowSearch={setShowSearch}
              themeProvider={themeProviderRef}
              toggleFullscreenMode={() => setFullScreenMode(!fullscreenMode)}
              isApiViewEnabled={apiViewMode}
            />
          </>
        ) : (
          ''
        )}
        {!isFisTheme() && <SessionExpiredDialogWrapper />}
        <MDXProvider components={MdxComponents}>
          {config.features.scrollTop === true ? <ScrollTop /> : ''}
          <Wrapper>
            {config.sidebar.enabled === true && excludeSidebar === false ? (
              <Sidebar
                show={!(config.features.fullScreenMode.hideSidebar && fullscreenMode)}
                location={location}
                css={hiddenMobile}
              />
            ) : (
              ''
            )}

            {data.mdx.frontmatter.noPaddingContent !== null &&
            data.mdx.frontmatter.noPaddingContent === true ? (
              <ContentNoPadding>{children}</ContentNoPadding>
            ) : (
              <Content>{children}</Content>
            )}

            {config.sidebar.enabled === true && excludeSidebar === false ? (
              <TableOfContents
                show={!(config.features.fullScreenMode.hideToc && fullscreenMode)}
                location={location}
                css={hiddenTablet}
              />
            ) : (
              ''
            )}
          </Wrapper>
        </MDXProvider>
        {includeFooter() && <Footer currentPathLocation={currentPathLocation} />}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default Layout;
