import { Grid, Stack, Typography, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

function DescList({ items }) {
  return items.map((e) => <li key={e}>{e}</li>);
}

export function Feature({ classNames, image, title, description, descriptionList, gridTemplate }) {
  const theme = process.env.THEME?.toLowerCase() || 'infinant';
  const matche910 = useMediaQuery('(min-width:910px)');
  const matches1500 = useMediaQuery('(min-width:1500px)');
  return (
    <Grid
      item
      xs={gridTemplate.xs}
      md={gridTemplate.md}
      lg={gridTemplate.lg}
      className={classNames}
    >
      <Stack spacing={3}>
        <Box component="img" className="feature-media" src={image} />
        <Box
          height={
            theme === 'fis'
              ? {
                  md: matche910 ? (matches1500 ? '2rem' : '3.6rem') : '4.2rem',
                }
              : undefined
          }
        >
          <Typography
            variant="body1"
            sx={{
              color: '#002060',
              fontWeight: 'bolder',
              fontSize: '1.2rem',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: '#0C120C;',
            paddingTop: { md: 1 },
          }}
        >
          {description}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#0C120C;',
          }}
        >
          <ul className="list">
            <DescList items={descriptionList} />
          </ul>
        </Typography>
      </Stack>
    </Grid>
  );
}
