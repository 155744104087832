module.exports = {
    "metadata": {
        "name": "interlace",
        "short_name": "Infinant",
        "description": "Infinant - Embedding finance in everyday events.",
        "language": "en",
        "url": "http://localhost",
        "pathPrefix": "/",
        "gaTrackingId": null,
        "siteImage": null,
        "favicon": "/assets/infinant/favicon/favicon-32x32.png",
        "themeColor": "#ff0000"
    },
    "header": {
        "logo": "/assets/infinant/branding/Infinant-logo-v2.png",
        "logoLink": "/",
        "helpUrl": "",
        "links": [
            {
                "text": "Product Guides",
                "link": "/product-guide/introduction",
                "external": false
            },
            {
                "text": "Docs",
                "link": "/api-documentation/getting-started",
                "external": false
            },
            {
                "text": "API Reference",
                "link": "/api-documentation/api-reference",
                "external": false
            }
        ],
        "enabled": true,
        "showTalkWithUsButton": true,
        "showSignIn": true
    },
    "sidebar": {
        "enabled": true,
        "ignoreIndex": false,
        "forcedNavOrder": [],
        "expanded": [],
        "groups": [
            {
                "order": 1,
                "path": "/api-documentation",
                "title": ":rocket: API Documentation"
            },
            {
                "order": 2,
                "path": "/product-guide",
                "title": ":writing_hand: Product Guides"
            }
        ],
        "links": null,
        "poweredBy": {},
        "showOnlySelectedGroup": true,
        "excludePaths": [
            "/api-documentation/api-reference",
            "/redirect/message",
            "/"
        ]
    },
    "pwa": {
        "manifest": {
            "name": "BooGi",
            "short_name": "BooGi",
            "start_url": "/",
            "background_color": "#6b37bf",
            "theme_color": "#6b37bf",
            "display": "standalone",
            "crossOrigin": "anonymous",
            "icon": "static/assets/infinant/favicon/favicon-32x32.png",
            "description": "Infinant - Embedding finance in everyday events.",
            "cache_busting_mode": "none",
            "include_favicon": false,
            "lang": "en"
        },
        "enabled": true
    },
    "social": {
        "facebook": "",
        "github": "",
        "gitlab": "",
        "instagram": "",
        "linkedin": "",
        "mail": "",
        "gmail": "",
        "slack": "",
        "twich": "",
        "twitter": "",
        "youtube": ""
    },
    "features": {
        "editOnRepo": {
            "editable": false,
            "location": "http://www.infinant.com/",
            "type": "github"
        },
        "search": {
            "enabled": true,
            "indexName": "developer-portal",
            "algoliaAppId": "5K3P1DY1IU",
            "algoliaSearchKey": "371104e7910b2dca5b08dbb9c699237d",
            "algoliaAdminKey": "4cb68b5eb01fcadf996c62b7d10ac706",
            "excerptSize": 8000,
            "engine": "algolia",
            "placeholder": "Search",
            "startComponent": "input",
            "debounceTime": 380,
            "snippetLength": 23,
            "hitsPerPage": 10,
            "showStats": true,
            "localSearchEngine": {
                "encode": "advanced",
                "tokenize": "full",
                "threshold": 2,
                "resolution": 30,
                "depth": 20
            },
            "pagination": {
                "enabled": true,
                "totalPages": 10,
                "showNext": true,
                "showPrevious": true
            }
        },
        "toc": {
            "show": false,
            "depth": 4
        },
        "previousNext": {
            "enabled": true,
            "arrowKeyNavigation": true
        },
        "scrollTop": true,
        "showMetadata": true,
        "propagateNetlifyEnv": true,
        "pageProgress": {
            "enabled": true,
            "excludePaths": [
                "/"
            ],
            "height": 3,
            "prependToBody": false,
            "color": "#A05EB5",
            "includePaths": [
                "/configuration/settingup/features"
            ]
        },
        "mermaid": {
            "language": "mermaid",
            "theme": "dark",
            "options": {},
            "width": 400,
            "height": 300
        },
        "rss": {
            "enabled": true,
            "showIcon": false,
            "title": "Infinant",
            "copyright": "2021® Infinant Inc.",
            "webMaster": "Infinant Support",
            "managingEditor": "Riaz Syed",
            "categories": [
                "Fintech",
                "Banking"
            ],
            "ttl": 60,
            "matchRegex": "^/",
            "outputPath": "/rss.xml",
            "generator": "infinant"
        },
        "darkMode": {
            "enabled": false,
            "default": false
        },
        "publishDraft": false,
        "fullScreenMode": {
            "enabled": false,
            "hideHeader": true,
            "hideToc": true,
            "hideSidebar": true
        }
    },
    "footer": {
        "enabled": true,
        "includePaths": [
            "/"
        ],
        "showMadeWithLoveLabel": true,
        "madeWithLoveLabelContent": "infinant Inc."
    }
};