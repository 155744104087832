import React from 'react';
import styled from '@emotion/styled';
import { onMobile } from '../../styles/responsive';
import customThemeStyles from '../../styles/custom-styles';

const topBarLinksException = (process.env.HEADER_LINKS_EXCEPTION || '')
  .trim()
  .toLowerCase()
  .split(',');

const Navigation = styled(({ className, links, location }) => {
  const currentPathLocation = location
    ? location.pathname.endsWith('/') && location.pathname.length > 1
      ? location.pathname.slice(0, -1)
      : location.pathname
    : '';
  const exceptionLink = '/api-documentation/api-reference';
  return (
    <nav css={{ display: 'flex', justifyContent: customThemeStyles?.header?.nav }}>
      <ul className={className}>
        {links
          ? links
              .filter((link) => !topBarLinksException.includes(link.text?.toLowerCase()?.trim()))
              .map((link, key) => {
                let cadDividida = link.link.split('/');
                let isSelected = currentPathLocation === link.link;
                if (
                  !link.external &&
                  !(link.link === exceptionLink) &&
                  !(currentPathLocation === exceptionLink)
                ) {
                  let expReg = new RegExp(cadDividida[1]);
                  isSelected = expReg.test(currentPathLocation);
                }
                const openRule = link.external ? '_blank' : '_self';
                if (link.link !== '' && link.text !== '') {
                  return (
                    <li key={key}>
                      <a
                        className={isSelected ? 'selected' : ''}
                        href={
                          link.link.includes('${domain}')
                            ? link.link.replace(
                                '${domain}',
                                process.env.DOMAIN || '.dev.infinant.com'
                              )
                            : link.link
                        }
                        target={openRule}
                        rel="noopener"
                        dangerouslySetInnerHTML={{ __html: link.text }}
                      />
                    </li>
                  );
                }
              })
          : null}
      </ul>
    </nav>
  );
})`
  display: flex;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  float: left;
  ${onMobile} {
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 10px;
    li {
      height: 37px;

      a {
        font-size: 14px;
        padding: 10px 15px;
      }
    }
  }
  li {
    list-style-type: none;
    display: flex;
    & > a:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: 0;
      left: 0;
      background: ${(props) => props.theme.header.font.hover};
      visibility: hidden;
      border-radius: 4px;
      transform: scaleX(0);
      transition: 0.25s linear;
    }
    & > a:focus:before,
    & > a:hover:before {
      visibility: visible;
      transform: scaleX(1);
    }
    a {
      /*font-family: 'Roboto';*/
      position: relative;
      color: ${(props) => props.theme.header.font.base};
      font-size: 16px;
      font-weight: 500;
      line-height: 1em;
      opacity: 1;
      padding: 10px 15px;
      &.selected,
      &:hover {
        color: ${(props) => props.theme.header.font.hover};
      }
      ${customThemeStyles?.header?.a}
    }
  }
`;

export default Navigation;
