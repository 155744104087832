import { Grid, Typography, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/system';
import React from 'react';
import './home.css';
import config from 'config';
import { FeatureList } from './features/list';
import { Hero } from './hero/hero';
import { mainMuiTheme } from '../../theme/mui-theme';
import TalkWithUsButton from './talkWithUs/TalkWithUsButton';

const mainTitle = config.content?.homePage?.title ?? 'Interlace Platform';
const companyName = config.content?.homePage?.companyName ?? 'Infinant';
const enableTalkWithUs = config.content?.homePage?.enableTalkWithUs ?? true;
const talkWithUsUrl =
  config.content?.homePage?.talkWithUsUrl ?? 'https://www.infinant.com/contact/';
const gettingStartedUrl =
  config.content?.homePage?.gettingStartedUrl ?? '/api-documentation/getting-started';
const theme = process.env.THEME?.toLowerCase() || 'infinant';

const overwriteForDefaultGettingStartButton = !(theme == 'fis')
  ? {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      ':hover': {
        color: '#000',
        backgroundColor: '#FFFFFF',
      },
      ':active': {
        color: '#000',
        backgroundColor: '#FFFFFF',
      },
      border: '1px solid #E8E8EF',
    }
  : {};

const homeViewData = {
  content1: {
    title: `${mainTitle}`,
    paragraphs: {
      infinant: [
        `${companyName} understands the benefits and best practices for adopting event-driven design and not staying stagnant with point-to-point APIs. We provide connectivity with a purpose - with tokenized, credential-free security.`,
        'Everyday life is asynchronous and when looking to embed finance in everyday events, you need a platform that can scale and deliver in real-time across many streams.',
        'The ability to asynchronously align services and data need to be at the center of any secure, elastic, distributed, and cloud-native platforms.',
        'Our platform can mesh your legacy point-to-point services with modern event-driven architectures to unlock an expanding set of data and transactions that can drive your business and provide personalized experiences for your users.',
      ],
      fis: [
        `FIS BaaS Hub will provide fintechs, merchants, and banks with the ability to build a financial services ecosystem, which is purpose built and delivers a differentiating financial experience.  Having access to regulated banking services requires the use of a FIS approved partner bank.  With FIS BaaS Hub, FIS will be able to bring that partnership to you.`,
        'FIS BaaS Hub will deliver access to banking and payment services via a chartered banking model, which will include fully integrated fintech partner propositions, delivered through our service, security, and second line support models.',
      ],
      legendbank: [
        `${companyName} understands the benefits and best practices for adopting event-driven design and not staying stagnant with point-to-point APIs. We provide connectivity with a purpose - with tokenized, credential-free security.`,
        'Everyday life is asynchronous and when looking to embed finance in everyday events, you need a platform that can scale and deliver in real-time across many streams.',
        'The ability to asynchronously align services and data need to be at the center of any secure, elastic, distributed, and cloud-native platforms.',
        'Our platform can mesh your legacy point-to-point services with modern event-driven architectures to unlock an expanding set of data and transactions that can drive your business and provide personalized experiences for your users.',
      ],
    },
    imageUrl: {
      infinant: '/assets/infinant/dev-portal/images/technology/orange-section-cube.svg',
      fis: '/assets/fis/branding/fis-hero-compressed.jpg',
      legendbank: '/assets/infinant/dev-portal/images/technology/orange-section-cube.svg',
    },
  },
  content2: {
    infinant: [
      {
        title: 'Event-Based Architecture',
        description:
          'Our platform is built with microservices to support reliable and guaranteed delivery.',
        list: [
          'Develop with agility to push events to consumers',
          'Centralized location to audit all applications',
          'Lower costs because events are push-based',
          'Maintain data consistency across your services',
        ],
        image: '/assets/infinant/dev-portal/images/technology/architecture-icon.svg',
      },
      {
        title: 'Enterprise Security',
        description:
          'Feel secure, we take care and protect your digital assets with the latest technology.',
        list: [
          'Encrypted data across the network',
          'Manage and monitor endpoint security',
          'Network performance monitoring',
          'OAuth2 to handle endpoint authorization',
        ],
        image: '/assets/infinant/dev-portal/images/technology/security-icon.svg',
      },
      {
        title: 'AWS Infrastructure',
        description:
          'Our platform runs on the most secure and reliable networks of the Fortune 500.',
        list: [
          'Security provided by AWS Global Infrastructure',
          'Built for performance',
          'Highly flexible and scalable',
          'Highest network availability for your services',
        ],
        image: '/assets/infinant/dev-portal/images/technology/awsinfra-icon.svg',
      },
      {
        title: 'Postman Collections',
        description: 'APIs are organized to ensure easy development and testing of our services.',
        list: [
          'API Schemas for our services',
          'Easy to understand documentation',
          'Mock servers to test APIs',
          'Available downloads to use the collections on your own Postman instance',
        ],
        image: '/assets/infinant/dev-portal/images/technology/postman-icon.svg',
      },
    ],
    fis: [
      {
        title: 'Best of Breed Fintechs',
        description:
          'Through our FIS Impact Ventures Accelerator and investments, we have built partnerships with industry leading fintechs. Our BaaS Hub fintech partners have integrated into the next generation suite of FIS solutions via APIs.',
        list: [],
        image: '/assets/fis/dev-portal/images/technology/connected-2.png',
      },
      {
        title: 'Fast and Easy Access to Innovative Fintech Technology',
        description:
          'FIS BaaS Hub will overcome the challenge of coordinating and orchestrating multiple technology partners, such as banks, fintechs, and merchants, while driving mass enablement. FIS BaaS Hub’s modular approach means fintechs, banks and businesses will be able to quickly and easily access sophisticated, cutting-edge features.',
        list: [],
        image: '/assets/fis/dev-portal/images/technology/cloud-management.png',
      },
      {
        title: 'Start Building Now',
        description:
          'Clients will be able to access pre-built tools to quickly configure financial service elements into user journeys and experiences. Full turnkey operations and back-office support means that clients will be able to add solutions without having to ramp up staff and operations.',
        list: [],
        image: '/assets/fis/dev-portal/images/technology/speed.png',
      },
    ],
    legendbank: [
      {
        title: 'Event-Based Architecture',
        description:
          'Our platform is built with microservices to support reliable and guaranteed delivery.',
        list: [
          'Develop with agility to push events to consumers',
          'Centralized location to audit all applications',
          'Lower costs because events are push-based',
          'Maintain data consistency across your services',
        ],
        image: '/assets/infinant/dev-portal/images/technology/architecture-icon.svg',
      },
      {
        title: 'Enterprise Security',
        description:
          'Feel secure, we take care and protect your digital assets with the latest technology.',
        list: [
          'Encrypted data across the network',
          'Manage and monitor endpoint security',
          'Network performance monitoring',
          'OAuth2 to handle endpoint authorization',
        ],
        image: '/assets/infinant/dev-portal/images/technology/security-icon.svg',
      },
      {
        title: 'AWS Infrastructure',
        description:
          'Our platform runs on the most secure and reliable networks of the Fortune 500.',
        list: [
          'Security provided by AWS Global Infrastructure',
          'Built for performance',
          'Highly flexible and scalable',
          'Highest network availability for your services',
        ],
        image: '/assets/infinant/dev-portal/images/technology/awsinfra-icon.svg',
      },
      {
        title: 'Postman Collections',
        description: 'APIs are organized to ensure easy development and testing of our services.',
        list: [
          'API Schemas for our services',
          'Easy to understand documentation',
          'Mock servers to test APIs',
          'Available downloads to use the collections on your own Postman instance',
        ],
        image: '/assets/infinant/dev-portal/images/technology/postman-icon.svg',
      },
    ],
  },
  content3: {
    image: {
      infinant: '/assets/infinant/dev-portal/images/technology/keep-building-latest.png',
      fis: '/assets/fis/dev-portal/images/technology/fis-motto.png',
      legendbank: '/assets/infinant/dev-portal/images/technology/keep-building-latest.png',
    },
  },
};

export default function Main() {
  return (
    <Grid container backgroundColor="#FBFBFB" justifyContent="center">
      <Hero
        title={homeViewData.content1.title}
        paragraphsDescription={homeViewData.content1.paragraphs[theme]}
        imageUrl={homeViewData.content1.imageUrl[theme]}
        owner={theme}
      />
      <Grid
        container
        item
        backgroundColor="#FBFBFB"
        maxWidth="1920px"
        sx={{ padding: { xs: '40px 40px 0 40px', md: '40px 96px 0 96px' } }}
      >
        <Grid container item>
          <FeatureList items={homeViewData.content2[theme]} owner={theme} />
        </Grid>
      </Grid>
      <Grid container item backgroundColor="#000" padding="40px" justifyContent="center">
        <Grid item backgroundColor="#000" maxWidth="1024px" justifyContent="center">
          <Box marginX="auto" component="img" src={homeViewData.content3.image[theme]} />
        </Grid>
      </Grid>
      {enableTalkWithUs && (
        <Grid
          container
          item
          backgroundColor="#FBFBFB"
          maxWidth="1920px"
          padding="40px"
          alignItems="center"
          sx={{
            justifyContent: { xs: 'center', sm: 'flex-start' },
            padding: { xs: '40px', md: '80px 96px 40px 96px' },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: '#002060;',
            }}
            m={{ xs: '15px', md: '10px' }}
          >
            Want more information? Please
          </Typography>
          <TalkWithUsButton show={enableTalkWithUs} talkWithUsUrl={talkWithUsUrl} />
        </Grid>
      )}
      <Grid
        container
        item
        backgroundColor="#FBFBFB"
        maxWidth="1920px"
        padding="40px"
        justifyContent="flex-end"
        sx={{ padding: { xs: '40px', md: '40px 96px 80px 96px' } }}
      >
        <Button
          variant="contained"
          sx={{
            width: { xs: '200px', sm: '350', md: '500px' },
            justifyContent: 'space-between',
            boxShadow: '0px 2px 10px #00000012',
            height: '57px',
            textTransform: 'capitalize',
            borderRadius: '10px',
            '&:hover': {
              color: '#FFFFFF',
              backgroundColor: mainMuiTheme.palette.primary.main,
            },
            ...overwriteForDefaultGettingStartButton,
          }}
          href={gettingStartedUrl}
        >
          <Typography color={theme === 'fis' ? 'white' : '#2E2E30'}>Getting Started</Typography>
          <ArrowForwardIcon sx={{ color: theme === 'fis' ? 'white' : '#2E2E30' }} />
        </Button>
      </Grid>
    </Grid>
  );
}
