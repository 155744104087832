import { useEffect, useState } from "react";

interface Props {
  onCounterZero?: () => void;
}

export function useRemainingTimeCounter({ onCounterZero }: Props) {
  const [remainingSessionSeconds, setRemainingSessionSeconds] = useState<number>();

  useEffect(() => {
    let timeoutId;
    if (remainingSessionSeconds === undefined) return;
    if (remainingSessionSeconds > 0) {
      timeoutId = setTimeout(() => setRemainingSessionSeconds(remainingSessionSeconds - 1), 1000);
    } else {
      if (onCounterZero) onCounterZero();
      setRemainingSessionSeconds(undefined);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [remainingSessionSeconds]);

  return [remainingSessionSeconds, setRemainingSessionSeconds] as const;
}
