import React from "react";
import { Button } from "@mui/material";

const TalkWithUsButton = ({show, talkWithUsUrl}) => {

    return ( show &&
        <Button
            variant="contained"
            sx={{
              borderRadius: '32px',
              height: '36px',
              width: '163px',
              textTransform: 'capitalize',
              backgroundColor: '#0C120C',
              ':hover': {
                backgroundColor: '#002060',
              },
            }}
            className="button-talWithUs"
            href={talkWithUsUrl}
          >
            Talk with us &gt;
        </Button>
    );
};

export default TalkWithUsButton;
