import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SignInButton from "../Button/SignInButton";

/**
 * Custom SignIn component meant to be used in the main navigation bar.
 * @returns
 */
const Auth0SignInButton = ( props ) => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();

  return isAuthenticated || isLoading? 
    (<></>) : 
    (<SignInButton logInCallBack={() => loginWithRedirect({appState: {returnTo: window.location.pathname,}})}/>);
};

export default Auth0SignInButton;
