import { AUTH_PROVIDER_NAME, COGNITO_PROVIDER } from "../../utils/auth-constants";
import React from 'react';
import {SessionExpiredDialogWrapperCognito} from "./wrapper.cognito";
import {SessionExpiredDialogWrapperAuth0} from "./wrapper.auth0";

export const SessionExpiredDialogWrapper = () => {
  if (AUTH_PROVIDER_NAME?.toUpperCase() === COGNITO_PROVIDER)
    return <SessionExpiredDialogWrapperCognito></SessionExpiredDialogWrapperCognito>
  else
    return <SessionExpiredDialogWrapperAuth0></SessionExpiredDialogWrapperAuth0>
}
