import React from "react";
import Auth0SignInButton from "./Auth0/Auth0SignInButton";

/**
 * Custom SigIn component meant to be used in the main navigation bar.
 * @param {boolean} show if true the component will be shown.
 * @returns
 */
const SignIn = ({ show = false }) => {
  return show && (<Auth0SignInButton/>);
};

export default SignIn;
