import { Feature } from './element';

export function FeatureList({ items, owner }) {
  const grid = {
    infinant: {
      xs: 12,
      md: 6,
      lg: 3,
    },
    fis: {
      xs: 12,
      md: 4,
    },
    legendbank: {
      xs: 12,
      md: 6,
      lg: 3,
    },
  };

  return items.map((item, index) => (
    <Feature
      key={item.title}
      classNames={`featureBoxes featureBox${index + 1}`}
      image={item.image}
      title={item.title}
      description={item.description}
      descriptionList={item.list}
      gridTemplate={grid[owner]}
    />
  ));
}
