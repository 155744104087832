import React from 'react';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import config from 'config';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
const IconWrapper = styled.a`
  display: flex;
  margin: 0 5px;
`;

const Footer = ({ currentPathLocation }) => {
  const theme = process.env.THEME?.toLowerCase() || 'infinant';

  if (theme === 'fis') {
    const pathsWithFixedFooter = ['/support/support'];
    const fixedFooter = pathsWithFixedFooter.includes(currentPathLocation);

    function useFixedFooter() {
      if (!fixedFooter) return {};
      return {
        position: 'absolute',
        bottom: 0,
      };
    }
    return (
      <Grid container backgroundColor="#FFF" justifyContent="center">
        <Grid
          container
          item
          backgroundColor="#FFF"
          color={'#9B9B9B'}
          maxWidth="1920px"
          sx={{
            padding: '0 40px',
            height: '60px;',
            ...useFixedFooter(),
          }}
        >
          <Grid
            item
            sx={{
              padding: {
                xs: '20px 0',
              },
            }}
          >
            <Typography sx={{ textAlign: 'left' }}>
              Access to regulated banking services on FIS BaaS Hub requires the use of a FIS
              approved partner bank.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const showMadeWithLoveLabel = config.footer?.showMadeWithLoveLabel;
  const madeWithLoveLabelContent = config.footer?.madeWithLoveLabelContent;

  return (
    <Grid
      container
      backgroundColor="#002060"
      justifyContent="center"
      height="102px"
      alignItems="center"
    >
      <Grid
        container
        item
        backgroundColor="#002060"
        sx={{
          justifyContent: { xs: 'center', sm: 'space-between' },
          padding: { xs: '40px 40px', md: '0 96px' },
        }}
        alignItems="center"
        color="#FFF"
      >
        <Grid
          item
          sx={{
            padding: {
              xs: '20px 0',
            },
          }}
        >
          <Typography sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
            {showMadeWithLoveLabel && (
              <span>
                Made with {<FavoriteBorderIcon />} by <b>{madeWithLoveLabelContent}</b>&nbsp;
              </span>
            )}
            © All Rights Reserved.
          </Typography>
        </Grid>
        <SocialWrapper>
          <IconWrapper href="https://www.linkedin.com/company/infinant-inc" target="_blank">
            <LinkedInIcon fontSize="large" />
          </IconWrapper>
          <IconWrapper href="https://twitter.com/Infinantinc" target="_blank">
            <TwitterIcon fontSize="large" />
          </IconWrapper>
        </SocialWrapper>
      </Grid>
    </Grid>
  );
};

export { Footer };
