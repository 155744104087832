import { useEffect, useState } from "react";
import { UserService } from "../../services";
import { TOKEN_VERIFICATION_MILLIS_INTERVAL } from "./constants";

interface Props {
  onSessionExpiration?: () => void;
}

export function useSessionVerification({ onSessionExpiration }: Props) {
  const [sessionExpired, setSessionExpired] = useState(false);

  const checkValidUser = async () => {
    const valid = await UserService.isAccessTokenValid();
    setSessionExpired(!valid);
    if (!valid) {
      if (onSessionExpiration) onSessionExpiration();
    }
  };

  useEffect(() => {
    const interval = setInterval(checkValidUser, TOKEN_VERIFICATION_MILLIS_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return [sessionExpired] as const;
}
