const theme = process.env.THEME?.toUpperCase();

let customThemeStyles;

const fisCustomStyles = {
  themeName: 'fis',
  MUIfonts: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
  },
  fonts: {
    'html, body': {
      fontFamily: 'Source Sans Pro',
      fontSize: '16px',
      scrollBehavior: 'smooth',
    },

    '*': {
      fontFamily: 'Source Sans Pro, sans-serif',
    },

    'h1, h2, h3': {
      fontFamily: 'Source Sans Pro, sans-serif',
    },

    'div.sl-leading-relaxed.sl-tracking-wide': {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontSize: '13px',
      fontWeight: 700,
      letterSpacing: '1.2px',
      lineHeight: '15.6px',
      color: '#808082',
    },

    ':root': {
      '--font-prose': 'Source Sans Pro, sans-serif',
      '--font-ui': 'Source Sans Pro, sans-serif',
      '--font-mono': 'Source Sans Pro, monospace',
    },
  },
  header: {
    container: `box-shadow: none;
    border-bottom: 1px solid #EBEBEB`,
    logoDocsContainer: `display: flex;
    align-self: flex-end;
    border: 0px solid black;
    border-radius: 0px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #fff;
    border-color: #fff;
    border-width: 0px;`,
    logoRightDivider: `border-right: 0`,
    nav: `center!important`,
    a: `font-weight: 600`,
  },
  sidebar: {
    NavigationWrapper: `border-right: 0px!important;`,
    activeNode: `border-right: 1px solid #DBDDDF!important;`,
    ContentTreeGroupTitle: `color: #000`,
  },
};

const infinantStyles = {
  themeName: 'infinant',
  MUIfonts: {
    fontFamily: [
      'system-ui',
      '-apple-system',
      '"system-ui"',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  fonts: {
    'html, body': {
      fontFamily:
        'Lato Regular, Lato Black, Neuzeit Grotesk Black, Neuzeit Grotesk Bold, sans-serif',
      fontSize: '16px',
      scrollBehavior: 'smooth',
    },

    '*': {
      fontFamily:
        'system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    },

    'h1, h2, h3': {
      fontFamily: 'Neuzeit Grotesk Black, sans-serif',
    },

    'div.sl-leading-relaxed.sl-tracking-wide': {
      fontFamily: 'Lato Regular, sans-serif',
      fontSize: '13px',
      fontWeight: 700,
      letterSpacing: '1.2px',
      lineHeight: '15.6px',
      color: '#808082',
    },

    ':root': {
      '--font-prose': 'Lato, sans-serif',
      '--font-ui': 'Lato, sans-serif',
      '--font-mono': 'Lato, monospace',
    },
  },
};

const themeHandler = () => {
  switch (theme) {
    case 'FIS':
      customThemeStyles = fisCustomStyles;
      break;
    case 'INFINANT':
      customThemeStyles = infinantStyles;
      break;
    default:
      customThemeStyles = infinantStyles;
      break;
  }
};

themeHandler();
export default customThemeStyles;
