import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SignOutButton from "../Button/SignOutButton";

/**
 * Custom SigOut component meant to be used in the main navigation bar.
 * @param {string} className custom logout className.
 * @returns
 */
const Auth0SignOutButton = ( props ) => {
  const {
    isAuthenticated,
    logout 
  } = useAuth0();
  
  return isAuthenticated ? 
    (<SignOutButton className={props.className} logOutCallBack={() => logout({returnTo: window.location.origin})}/>) : 
    (<></>);
};

export default Auth0SignOutButton;
