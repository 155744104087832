
const evaluateMillis = (value: string | undefined, defaultValue: number) => {
  if (value === undefined || value === null || value.trim().length === 0) return defaultValue;
  const millis = parseInt(value);
  if (millis === undefined) return defaultValue;
  return millis;
};

const evaluateString = (value: string | undefined) => {
  if (!value || value.trim().length === 0) {
    return;
  }
  return value;
};

export const getIdleTimeout = (): number => {
  const defaultValue = 1_800_000;
  return evaluateMillis(process.env.IDLE_TIMEOUT_MILLIS, defaultValue) || defaultValue;
};

export const isFisTheme = (): boolean => {
  return process.env.THEME?.toLowerCase() === 'fis';
}

export const isInfinantTheme = (): boolean => {
  return process.env.THEME?.toLowerCase() === 'infinant';
}

export const getAudienceId = (): string => {
  const defaultValue = `https://api-console-${process.env.ENVIRONMENT || "dev"}.infinant.com`;
  return evaluateString(process.env.AUTH0_AUDIENCE_ID)?.toLowerCase() || defaultValue;
};

export const getFisUrl = (): string => {
  const fisUrl = `https://web.${process.env.ENVIRONMENT || "fis-sandbox"}.infinant.com`;
  return fisUrl;
};
