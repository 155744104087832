import React from "react";
import Auth0SignOutButton from "./Auth0/Auth0SignOutButton";

/**
 * Custom SigOut component meant to be used in the main navigation bar.
 * @param {boolean} show if true the component will be shown.
 * @returns
 */
const SignOut = ({ show = true }) => {
   return show && (<Auth0SignOutButton/>);
};

export default SignOut;
