import { ConfirmationDialog } from "../ConfirmationDialog";
import { UserService } from "../../services";
import { useSessionVerification } from "./session-verification";
import { usePromptTime } from "./prompt-time";
import { useRemainingTimeCounter } from "./remaining-time-counter";

const body1 =
  "You're being timed out due to inactivity. Please choose to " +
  "stay signed in or logoff. Otherwise, you will logged off automatically.";

const body2 =
  "You're being timed out due to inactivity. Please choose to  logoff now. Otherwise, you will logged off automatically.";

const getBody = (allowStaySignedIn: boolean): string => {
  if (allowStaySignedIn) {
    return body1;
  }
  return body2;
};

export function SessionExpiredDialog() {
  const onSessionExpiration = (): void => {
    setRemainingSessionSeconds(10);
  };
  const executeOnIdle = (): void => {
    onLogOff().then(() => {});
  };

  const onCounterZero = (): void => {
    onLogOff().then(() => {});
  };

  const [sessionExpired] = useSessionVerification({ onSessionExpiration: onSessionExpiration });
  const [promptEnabled, remainingIdle, activate] = usePromptTime({ executeOnIdle: executeOnIdle });
  const [remainingSessionSeconds, setRemainingSessionSeconds] = useRemainingTimeCounter({
    onCounterZero: onCounterZero,
  });

  const onStayLoggedIn = () => {
    setRemainingSessionSeconds(undefined);
    activate();
    UserService.refreshSession().then(async (refreshed) => {
      if (!refreshed) {
        await onLogOff();
      }
    });
  };

  const onLogOff = async () => {
    await UserService.signOut();
  };

  const isIdleFlow = () => {
    return promptEnabled && !sessionExpired;
  };

  const shouldModalBeOpened = () => {
    return promptEnabled || sessionExpired;
  };

  return (
    <ConfirmationDialog
      title={"Session Timeout"}
      body={getBody(isIdleFlow())}
      cancelText={"Log off"}
      confirmText={"Stay Logged In"}
      open={shouldModalBeOpened()}
      onConfirm={onStayLoggedIn}
      onCancel={onLogOff}
      remainingSeconds={isIdleFlow() ? remainingIdle : remainingSessionSeconds}
      hideConfirm={sessionExpired}
    ></ConfirmationDialog>
  );
}
