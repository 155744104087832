import { Theme } from "@mui/material";
import { getInfinantMuiTheme } from "./themes/infinant";
import { getFisMuiTheme } from "./themes/fis";

// @ts-ignore
const customTheme = process.env.THEME?.toLowerCase() || 'infinant';
let theme: Theme;
if (customTheme === "fis") {
  theme = getFisMuiTheme();
} else {
  theme = getInfinantMuiTheme();
}

export const mainMuiTheme: Theme = theme;
