import { useEffect, useState } from "react";
import { UserService } from "../services";
import { AuthenticatedUserData } from "../model";

export const useLoggedUser = () => {
  const [user, setUser] = useState<AuthenticatedUserData>(null);
  useEffect(() => {
    const initUserData = async () => {
      let userData = null;
      try {
        userData = await UserService.getUserData();
      } catch (e) {
      } finally {
        setUser(userData);
      }
    };
    initUserData();
  }, []);
  return [user, setUser] as const;
};
