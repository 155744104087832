import defaultColors from './colors';

const fisColors = {
  primary: defaultColors.primaryFis,
  secondary: defaultColors.secondaryFis,
  background: defaultColors.headerBackgroundFis,
  sidebarBackgroud: defaultColors.sidebarBackgroundFis,
  activeSidebarOption: defaultColors.secondaryFis,
  textLink: defaultColors.textLinkFis,
};

const infinantColors = {
  primary: defaultColors.blue,
  secondary: defaultColors.customYellow,
  background: '#F5F7F9',
  sidebarBackgroud: '#F5F7F9',
  activeSidebarOption: defaultColors.blue,
  textLink: defaultColors.blue,
};

const theme = process.env.THEME;

const themeHandler = () => {
  switch (theme) {
    case 'FIS':
      return fisColors;
      break;
    case 'INFINANT':
      return infinantColors;
      break;
    default:
      return infinantColors;
      break;
  }
};

let themeColors = themeHandler();

const colors = {
  ...defaultColors,
  primaryDark: defaultColors.blueDark,
  font: '#333334',
  fontDark: '#121213',
  mainBackground: '#fefefe',
  border: '#DBDDDF',
  hover: defaultColors.blue,
  shadow: defaultColors.gray + '33',
  ...themeColors,
};

export default {
  colors: colors,
};
