import { AuthenticatedUserData } from "../../model";
import {UserServiceAuth0Impl} from "./auth0/user.service";

export type UserServiceType = {
  isUserLogged: () => Promise<boolean>;
  getUserData?: () => Promise<AuthenticatedUserData>;
  signOut: () => Promise<boolean>;
  isAccessTokenValid?: () => Promise<boolean>;
  refreshSession?: () => Promise<boolean>;
};

export const UserService: UserServiceType = UserServiceAuth0Impl;
